import "./TransactionsSearchArea.css";
import TextInput from "../../../generalComponents/inputFields/textInputComponent/TextInputComponent";
import SelectComponent from "../../../generalComponents/inputFields/selectComponent/SelectComponent";
import Button from "../../../generalComponents/buttons/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../generalComponents/loaders/Loader";
import SearchIcon from '@mui/icons-material/Search';
import dayjs from "dayjs";
import { trxTypesDetector } from "../../../utils/helpers/trxtypesDetector";
import { getStatusCodeIdByName } from "../../../utils/helpers/getStatusCodeIdByName";
import { getStatusCodesList } from "../../../utils/helpers/getStatusCodesList";
import { changeTransactionsFieldsForView } from "../../../utils/helpers/changeTransactionsFieldsForView";
import { searchingValidation } from "../../../utils/helpers/searchingValidation";
import { postDataApi } from "../../../apis/postDataApi";
import { useRefreshToken } from "../../../utils/customHooks/useRefreshToken";
import { useLogOutUser } from "../../../utils/customHooks/useLogOutUser";
import { exportDataApi } from "../../../apis/exportDataApi";
import { colors } from "../../../assets/styles/colors";
import { urls } from "../../../constants/urls/urls";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const TransactionsSearchArea = ({ 
    pageSize,
    transactionsPageForSearch,
    setIsSearchedTransactionsData,
    setSearchedTransactionsPageCount,
    isSearched,
    setIsSearched,
    transactionsSearchFields,
    transactionsSortFields,
    transactionTypes, 
    statusCodes,
    setTransactions
}) => {
    const [ transactionsSearchInfo, setTransactionsSearchInfo ] = useState({
        hasSearchParams: false,
        searchField: null,
        searchValue: null,
        transactionType_id: null,
        startDate: new Date(Date.now() - 604800000),
        endDate: new Date(Date.now()),
        statusCode_id: null,
        order_by: null,
        desc: true
    });
    const [ currentSearchPage, setCurrentSearchPage ] = useState(1);
    const [ showLoading, setShowLoading ] = useState(false);
    const [ prevSearchInfo, setPrevSearchInfo ] = useState({
        ...transactionsSearchInfo,
        startDate: null,
        endDate: null
    });
    const [ searchByFieldEmptyError, setSearchByFieldEmptyError ] = useState(false);
    const [ searchDataFieldEmptyError, setSearchDataFieldEmptyError ] = useState(false);
    const [ showConnectionError, setShowConnectionError ] = useState(false);
    const refreshToken = useRefreshToken();
    const logOutUser = useLogOutUser();
    const { i18n, t } = useTranslation();

    const trxTypesList = [];
    transactionTypes.map((trxType) => trxTypesList.push(trxType[`name_${i18n.language}`]));

    useEffect(() => {
        setTransactionsSearchInfo({
            ...transactionsSearchInfo,
            order_by: transactionsSortFields?.order_by,
            desc: transactionsSortFields?.desc
        });
    }, [transactionsSortFields]);

    useEffect(() => {
        if (
            transactionsSearchInfo.order_by !== prevSearchInfo.order_by ||
            transactionsSearchInfo.desc !== prevSearchInfo.desc
        ) {
            prevSearchInfo.order_by = transactionsSearchInfo.order_by;
            prevSearchInfo.desc = transactionsSearchInfo.desc;

            callForTransactionsSearchedData();
        }
    }, [transactionsSearchInfo]);

    const callForTransactionsSearchedData = () => {
        if (!transactionsSearchInfo.searchField) transactionsSearchInfo.searchField = null;
        if (!transactionsSearchInfo.transactionType_id) transactionsSearchInfo.transactionType_id = null;

        let searchParams = {};
        for (const field in transactionsSearchInfo) {
            if (field !== "hasSearchParams") {
                searchParams[field] = transactionsSearchInfo[field];
            }
        }

        const makeCallForSearchedTransactions = async () => {
            try {
                if (searchParams.startDate) searchParams.startDate = dayjs(searchParams.startDate).format("YYYY-MM-DD HH:mm:ss");
                if (searchParams.endDate) searchParams.endDate = dayjs(searchParams.endDate).format("YYYY-MM-DD HH:mm:ss");

                setShowLoading(true);
                const response = await postDataApi(urls.SEARCH_TRANSACTIONS_URL 
                                            + `?page=${transactionsPageForSearch}&size=${pageSize}`, searchParams);
                setShowLoading(false);

                if (response.status === 200) {
                    setTransactions(changeTransactionsFieldsForView(response.data.items, transactionsPageForSearch, 
                        pageSize, transactionsSearchInfo.desc, response.data.total));
                    setIsSearchedTransactionsData(true);
                    setSearchedTransactionsPageCount(response.data.pages);
                    setCurrentSearchPage(response.data.page);
                } else if (response.status === 401) {
                    setShowLoading(true);
                    await refreshToken();
                    setShowLoading(false);

                    setShowLoading(true);
                    const response = await postDataApi(urls.SEARCH_TRANSACTIONS_URL 
                                                + `?page=${transactionsPageForSearch}&size=${pageSize}`, searchParams);
                    setShowLoading(false);

                    if (response.status === 200) {
                        setTransactions(changeTransactionsFieldsForView(response.data.items, transactionsPageForSearch, 
                            pageSize, transactionsSearchInfo.desc, response.data.total));
                        setIsSearchedTransactionsData(true);
                        setSearchedTransactionsPageCount(response.data.pages);
                        setCurrentSearchPage(response.data.page);
                    } else {
                        logOutUser();
                    }
                }
            } catch (err) {
                console.log("Error: ", err);
            }
        };
        makeCallForSearchedTransactions();
    };

    if (transactionsPageForSearch !== currentSearchPage) {
        setCurrentSearchPage(transactionsPageForSearch);
        callForTransactionsSearchedData();
    }

    const onCliCkExportBtn = async () => {
        setShowConnectionError(false);
        try {
            await exportDataApi(urls.EXPORT_TRANSACTIONS_URL)
        } catch (err) {
            setShowConnectionError(true);
        }
    }

    return (
        <div className="transactions-search-area">
            <form className="transactions-search-form" onSubmit={(evt) => {
                evt.preventDefault();

                const makeSearchCall = searchingValidation(
                    transactionsSearchInfo,
                    setTransactionsSearchInfo,
                    prevSearchInfo,
                    setPrevSearchInfo,
                    isSearched,
                    setIsSearched,
                    setSearchDataFieldEmptyError,
                    setSearchByFieldEmptyError
                );

                if (makeSearchCall) {
                    callForTransactionsSearchedData();
                }
            }}>
                <div className="transactions-search-inputs">
                    <div className="transactions-search-input-fields">
                        <SelectComponent label={t("searchArea.searchBy")}
                                         chooseData={Object.keys(transactionsSearchFields)} 
                                         hasFirstRow={true}
                                         firstRowLabel="------"
                                         firstRowValue=""
                                         width="150px"
                                         existsError={searchByFieldEmptyError}
                                         errorText={t("searchArea.emptyFieldError")} 
                                         onChooseHandler={(evt) => {
                                            setSearchByFieldEmptyError(false);
                                            if (transactionsSearchFields[evt.target.value] === "rrn") {
                                                setTransactionsSearchInfo({
                                                    ...transactionsSearchInfo,
                                                    searchField: transactionsSearchFields[evt.target.value],
                                                    startDate: null,
                                                    endDate: null
                                                });
                                            } else if (!evt.target.value) {
                                                setTransactionsSearchInfo({
                                                    ...transactionsSearchInfo,
                                                    searchField: null,
                                                    startDate: new Date(Date.now() - 604800000),
                                                    endDate: new Date(Date.now())
                                                });
                                            } else {
                                                setTransactionsSearchInfo({
                                                    ...transactionsSearchInfo,
                                                    searchField: transactionsSearchFields[evt.target.value],
                                                    startDate: new Date(Date.now() - 604800000),
                                                    endDate: new Date(Date.now())
                                                });
                                            }
                                        }}/>
                        <TextInput label={t("searchArea.searchData")}
                                   existsError={searchDataFieldEmptyError}
                                   errorText={t("searchArea.emptyFieldError")}
                                   onChangeHandler={(evt) => {
                                       setSearchDataFieldEmptyError(false);
                                       setTransactionsSearchInfo({ 
                                           ...transactionsSearchInfo,
                                           searchValue: (evt.target.value)
                                       })
                                   }} />
                        <SelectComponent label={t("searchArea.chooseTrxType")}
                                         hasFirstRow={true}
                                         firstRowLabel={t("------")}
                                         firstRowValue={null}
                                         chooseData={trxTypesList}
                                         width={"250px"}
                                         marginLeft={"10px"}
                                         onChooseHandler={(evt) => {
                                            setTransactionsSearchInfo({
                                                ...transactionsSearchInfo,
                                                transactionType_id: trxTypesDetector[evt.target.value]
                                            });
                                         }} />
                        <SelectComponent label={t("searchArea.chooseTrxStatus")}
                                         hasFirstRow={true}
                                         firstRowLabel={t("------")}
                                         firstRowValue={null}
                                         chooseData={getStatusCodesList(statusCodes)}
                                         width={"300px"}
                                         marginLeft={"10px"}
                                         onChooseHandler={(evt) => {
                                            setTransactionsSearchInfo({
                                                ...transactionsSearchInfo,
                                                statusCode_id: getStatusCodeIdByName(statusCodes, evt.target.value)
                                            });
                                         }} />
                    </div>
                    <div className="transactions-search-calendar-fields">
                        <div className="transactions-search-date">
                            <span>{t("searchArea.startDate")}</span>
                            <DatePicker dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        isClearable
                                        showIcon
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={"05.01.2024"}
                                        maxDate={Date.now()}
                                        showYearDropdown
                                        selected={transactionsSearchInfo.startDate}
                                        onChange={(date) => setTransactionsSearchInfo({
                                            ...transactionsSearchInfo,
                                            startDate: date
                                        })} />
                        </div>
                        <div className="transactions-search-date">
                            <span>{t("searchArea.endDate")}</span>
                            <DatePicker dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        isClearable
                                        showIcon
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={"05.01.2024"}
                                        maxDate={Date.now()}
                                        showYearDropdown
                                        selected={transactionsSearchInfo.endDate}
                                        onChange={(date) => setTransactionsSearchInfo({
                                            ...transactionsSearchInfo,
                                            endDate: date
                                        })} />
                        </div>
                    </div>                    
                </div>
                <div className="transactions-search-buttons">
                    <Button label={t("searchArea.searchBtn")}
                            type="submit"
                            endIcon={<SearchIcon />}
                            marginRight="10px" />
                    <Button label={t("export.reporting")}
                            onClickHandler={onCliCkExportBtn} />
                </div>
            </form>
            {showConnectionError &&
                <p style={{ color: colors.loginFailedColor, marginTop: 0 }} className="transactions-page-search-export-error-text">
                    {t("generalErrors.connectionError")}
                </p>
            }
            {showLoading &&
                <Loader />
            }
        </div>
    );
};

export default TransactionsSearchArea;