export const monthsToDates = [
    {
        name: "Jan",
        fullName: "January",
        days: 31
    },
    {
        name: "Feb",
        fullName: "February",
        days: 28
    },
    {
        name: "Mar",
        fullName: "March",
        days: 31
    },
    {
        name: "Apr",
        fullName: "April",
        days: 30
    },
    {
        name: "May",
        fullName: "May",
        days: 31
    },
    {
        name: "Jun",
        fullName: "June",
        days: 30
    },
    {
        name: "Jul",
        fullName: "July",
        days: 31
    },
    {
        name: "Aug",
        fullName: "August",
        days: 31
    },
    {
        name: "Sep",
        fullName: "September",
        days: 30
    },
    {
        name: "Oct",
        fullName: "October",
        days: 31
    },
    {
        name: "Nov",
        fullName: "November",
        days: 30
    },
    {
        name: "Dec",
        fullName: "December",
        days: 31
    },
];