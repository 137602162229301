import './LoginForm.css';
// import ForgotPassword from "../forgotPassword/ForgotPassword";
import TextInput from '../../../../generalComponents/inputFields/textInputComponent/TextInputComponent';
import Button from "../../../../generalComponents/buttons/Button";
import ModalComponent from '../../../../generalComponents/modalComponent/ModalComponent';
import ErrorModalBody from '../../../../generalComponents/modalComponent/errorModalBody/ErrorModalBody';
import Loader from "../../../../generalComponents/loaders/Loader";
import { loginApi } from '../../../../apis/loginApi';
import { urls } from '../../../../constants/urls/urls';
import { colors } from '../../../../assets/styles/colors';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editRole, editUsername, editToken } from '../../../../redux/slices/authorization/authSlice';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [ loginParams, setLoginParams ] = useState({
        username: "",
        password: ""
    });
    const [ emptyUsernameError, setEmptyUsernameError ] = useState(false);
    const [ emptyPasswordError, setEmptyPasswordError ] = useState(false);
    const [ wrongUsernamePasswordError, setWrongUsernamePasswordError ] = useState(false);
    const [ showLoading, setShowLoading ] = useState(false);
    const [ openCloseModal, setOpenCloseModal ] = useState(false);

    const onChangeUsernameHandler = (evt) => {
        setLoginParams({
            ...loginParams,
            username: evt.target.value
        });
    }

    const onChangePasswordHandler = (evt) => {
        setLoginParams({
            ...loginParams,
            password: evt.target.value
        });
    }

    const makeCallForUserData = () => {
        try {
            const loadUserData = async () => {
                setShowLoading(true);
                const response = await loginApi(
                    urls.LOGIN_URL, 
                    loginParams
                );
                setShowLoading(false);

                if (response.status === 200) {
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                    localStorage.setItem("username", loginParams.username);
                    localStorage.setItem("role", "bank");

                    dispatch(editUsername(loginParams.username));
                    dispatch(editRole("bank"));
                    dispatch(editToken(response.data.access_token));

                    window.location.reload();
                } else if (response.status === 401) {
                    setWrongUsernamePasswordError(true);
                } else {
                    throw new Error("Connection error!");
                }                
            }
            loadUserData();
        } catch(err) {
            setOpenCloseModal(true);
        }
    }

    const onClickHandler = async (evt) => {
        evt.preventDefault();

        setEmptyUsernameError(false);
        setEmptyPasswordError(false);
        setWrongUsernamePasswordError(false);

        if (!loginParams.username.length || !loginParams.password.length) {
            if (!loginParams.username.length) setEmptyUsernameError(true);
            if (!loginParams.password.length) setEmptyPasswordError(true);
        } else {
            makeCallForUserData();
        }
    }

    return (
        <>
            <form action="" className="login-form">
                <TextInput label={t("userSection.username")}
                        size='normal'
                        onChangeHandler={onChangeUsernameHandler}
                        existsError={emptyUsernameError}
                        errorText={t("userSection.emptyUsernameError")} />
                <TextInput label={t("userSection.password")}
                        onChangeHandler={onChangePasswordHandler}
                        size='normal'
                        height='56px'
                        isPassword={true}
                        existsError={emptyPasswordError}
                        errorText={t("userSection.emptyPasswordError")}
                        marginTop="15px" />
                {/* <ForgotPassword /> */}
                {wrongUsernamePasswordError &&
                    <div className="login-error-message-div">
                        <label style={{ color: colors.loginFailedColor}} className="login-error-message">
                            {t("userSection.wrongUsernamePassword")}
                        </label>
                    </div>                    
                }
                <div className="login-button">
                    <Button type="submit" 
                        label="Login" 
                        backgroundColor={colors.originalBgColor}
                        hoverColor={colors.originalHoverColor}
                        width="220px"
                        onClickHandler={onClickHandler}
                    />
                </div>
            </form>
            {showLoading &&
                <Loader />
            }
            {openCloseModal &&
                <ModalComponent onCloseHandler={() => setOpenCloseModal(false)} 
                                isOpen={openCloseModal} 
                                title="Connection error!"
                                body={<ErrorModalBody />}
                                bgcolor="red"
                />
            }
        </>
    );
};

export default LoginForm;