import "./DeleteTerminalData.css";
import Button from "../../../generalComponents/buttons/Button";
import SuccessAnimation from "../../../generalComponents/successAnimation/SuccessAnimation";
import Loader from "../../../generalComponents/loaders/Loader";
import { putDataApi } from "../../../apis/putDataApi";
import { useRefreshToken } from "../../../utils/customHooks/useRefreshToken";
import { useLogOutUser } from "../../../utils/customHooks/useLogOutUser";
import { urls } from "../../../constants/urls/urls";
import { colors } from "../../../assets/styles/colors";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const DeleteTerminalData = ({ 
    terminal, 
    setIsTermDataDeleted,
    isTermDataDeleted,
    onCloseHandler 
}) => {
    const [ showLoading, setShowLoading ] = useState(false);
    const [ showSuccessAnimation, setShowSuccessAnimation ] = useState(false);
    const refreshToken = useRefreshToken();
    const logOutUser = useLogOutUser();
    const { t } = useTranslation();

    const onDeleteClickHandler = async () => {
        setShowLoading(true);
        const response = await putDataApi(urls.CLOSE_TERMINAL_URL + `${terminal.id}`);
        setShowLoading(false);

        if (response.status === 200) {
            setIsTermDataDeleted(!isTermDataDeleted);
            setShowSuccessAnimation(true);
            setTimeout(() => {
                onCloseHandler();
            }, 2500);
        } else if (response.status === 401) {
            setShowLoading(true);
            await refreshToken();
            setShowLoading(false);

            setShowLoading(true);
            const response = await putDataApi(urls.CLOSE_TERMINAL_URL + `${terminal.id}`);
            setShowLoading(false);

            if (response.status === 200) {
                setIsTermDataDeleted(!isTermDataDeleted);
                setShowSuccessAnimation(true);
                setTimeout(() => {
                    onCloseHandler();
                }, 2500);
            } else {
                logOutUser();
            }
        }
    };

    return (
        <div className="delete-term-data-content">
            <p>{t("questions.doYouWantToInactivate")} <b>{terminal.serial_number}</b> {t("questionsPostfixes.terminalsDataWithSearial")}</p>
            <div className="delete-term-data-buttons">
                <Button label={t("operations.inactivate")} 
                        backgroundColor={colors.cancelBgColor}
                        hoverColor={colors.cancelHoverColor}
                        marginRight="10px"
                        onClickHandler={() => onDeleteClickHandler()} />
                <Button label={t("operations.cancel")}
                        backgroundColor={colors.whiteColor}
                        hoverColor={colors.whiteHoverColor}
                        color={colors.cancelBgColor}
                        onClickHandler={() => onCloseHandler()} />
            </div>
            {showSuccessAnimation &&
                <SuccessAnimation />
            }
            {showLoading &&
                <Loader />
            }
        </div>
    )
};

export default DeleteTerminalData;