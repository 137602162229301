import { refreshTokenApi } from "../../apis/refreshTokenApi";
import { Headers } from "../../constants/configs/configs";
import { urls } from "../../constants/urls/urls";
import { editToken } from "../../redux/slices/authorization/authSlice";
import { useDispatch } from "react-redux";
import { useLogOutUser } from "./useLogOutUser"

export const useRefreshToken = () => {
    const dispatch = useDispatch();
    const logOutUser = useLogOutUser();

    return async () => {
        const response = await refreshTokenApi(urls.REFRESH_TOKEN_URL);

        if (response.status === 200) {
            Headers["Authorization"] = `Bearer ${response.data.access_token}`;
            localStorage.setItem("token", response.data.access_token);
            dispatch(editToken(response.data.access_token));

            return true;
        } else {
            logOutUser();
        }
    }
    
};