export const terminalsSearchFields = {
    "Terminal ID": "terminalId",
    "Merchant ID": "merchantId",
    "S/N": "serial_number",
    // "MCC": "mcc",
    // "Active": "is_active",
    // "POS model": "posModel",
    "Merchant name": "merchantNameLocal",
    "TAX": "merchantTin",
    // "City": "city",
    "Merchant address": "merchantAddressLocal",
};