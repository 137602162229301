export const dateIntervalDetector = {
    "Վերջին մեկ շաբաթ": "week",
    "Last one week": "week",
    "Последняя неделя": "week",
    "Վերջին մեկ ամիս": "month",
    "Last one month": "month",
    "Последний месяц": "month",
    "Վերջին երեք ամիս": "threeMonths",
    "Last three months": "threeMonths",
    "Последние три месяца": "threeMonths",
    "Վերջին վեց ամիս": "sixMonths",
    "Last six months": "sixMonths",
    "Последние шесть месяцев": "sixMonths",
    "Վերջին մեկ տարի": "year",
    "Last one year": "year",
    "Последний год": "year",
};