import "./LoginContent.css";
import LoginForm from "../loginForm/LoginForm";

const LoginContent = () => {
    return (
        <div className="login-content">
            <LoginForm />
        </div>
    );
};

export default LoginContent;