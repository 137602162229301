import { checkOnlyArmLettersSymbolsNumbers } from "./checkOnlyArmLettersSymbolsNumbers";
import { checkOnlyEngLettersSymbolsNumbers } from "./checkOnlyEngLettersSymbolsNumbers";

export const checkFieldsValidation = (
    { 
        mcc_id, 
        merchantNameGlobal, 
        merchantNameLocal, 
        merchantAddressGlobal, 
        merchantAddressLocal, 
        city_id,
    },
    [
        setEmptyMccError,
        setEmptyMerchantNameError,
        setInvalidMerchantNameError,
        setLongMerchantNameError,
        setEmptyMerchantNameInAmError,
        setInvalidMerchantNameInAmError,
        setLongMerchantNameInAmError,
        setEmptyMerchantAddressError,
        setInvalidMerchantAddressError,
        setLongMerchantAddressError,
        setEmptyMerchantAddressInAmError,
        setInvalidMerchantAddressInAmError,
        setLongMerchantAddressInAmError,
        setEmptyMerchantCityError,
    ],
) => {
    let existsError = false;

    if (!mcc_id) {
        existsError = true;
        setEmptyMccError(true);
    }

    if (!merchantNameGlobal) {
        existsError = true;
        setEmptyMerchantNameError(true);
    } else if (checkOnlyEngLettersSymbolsNumbers(merchantNameGlobal)) {
        existsError = true;
        setInvalidMerchantNameError(true);
    } else {
        if (merchantNameGlobal.length > 99) {
            existsError = true;
            setLongMerchantNameError(true);
        }
    }

    if (!merchantNameLocal) {
        existsError = true;
        setEmptyMerchantNameInAmError(true);
    } else if (checkOnlyArmLettersSymbolsNumbers(merchantNameLocal)) {
        existsError = true;
        setInvalidMerchantNameInAmError(true);
    } else {
        if (merchantNameLocal.length > 99) {
            existsError = true;
            setLongMerchantNameInAmError(true);
        }
    }

    if (!merchantAddressGlobal) {
        existsError = true;
        setEmptyMerchantAddressError(true);
    } else if (checkOnlyEngLettersSymbolsNumbers(merchantAddressGlobal)) {
        existsError = true;
        setInvalidMerchantAddressError(true);
    } else {
        if (merchantAddressGlobal.length > 99) {
            existsError = true;
            setLongMerchantAddressError(true);
        }
    }

    if (!merchantAddressLocal) {
        existsError = true;
        setEmptyMerchantAddressInAmError(true);
    } else if (checkOnlyArmLettersSymbolsNumbers(merchantAddressLocal)) {
        existsError = true;
        setInvalidMerchantAddressInAmError(true);
    } else {
        if (merchantAddressLocal.length > 99) {
            existsError = true;
            setLongMerchantAddressInAmError(true);
        }
    }

    if (!city_id) {
        existsError = true;
        setEmptyMerchantCityError(true);
    }

    return existsError;
};