export const paths = {
    LOGIN: "/login",
    TERMINALS: "/terminals",
    TRANSACTIONS: "/transactions",
    STATISTICS: "/statistics",
    MCC_CODES: "/mcc-codes",
    CITIES: "/cities",
    USERS: "/users",
    PAYMENT_SYSTEMS: "/payment-systems",
    MAIN: "/",
    ANY: "/*"
};