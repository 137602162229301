export const trxTypesDetector = {
    "Sale": 1,
    "Վաճառք": 1,
    "Продажа": 1,
    "Cancel": 2,
    "Չեղարկում": 2,
    "Отмена": 2,
    "Refund": 3,
    "Վերադարձ": 3,
    "Возврат": 3,
};