export const checkOnlyArmLettersSymbolsNumbers = (text) => {
    let existsError = true;

    for (let i = 0; i < text.length; i++) {
        if (
            (text.charCodeAt(i) >= 1 && text.charCodeAt(i) <= 64) ||
            (text.charCodeAt(i) >= 91 && text.charCodeAt(i) <= 95) ||
            (text.charCodeAt(i) >= 123 && text.charCodeAt(i) <= 127) ||
            /^[ա-ֆԱ-Ֆ]+$/.test(text[i]) ||
            (text.charCodeAt(i) === 1415)
        ) {
            existsError = false;
        } else return true;
    }

    return existsError;
};