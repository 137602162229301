import { monthsToDates } from "../../constants/dateTimes/monthsToDates";

export const getDates = {
    oneYearAgoDateByMilliseconds() {
        const currentYear = new Date(Date.now()).getFullYear();
        const oneYearMilliseconds = (currentYear % 4 === 0) ? 31536000000 : 31449600000;

        return Date.now() - oneYearMilliseconds
                          - new Date(Date.now()).getHours() * 60 * 60 * 1000 +
                          - new Date(Date.now()).getMinutes() * 60 * 1000 +
                          - new Date(Date.now()).getSeconds() * 1000 +
                          - new Date(Date.now()).getMilliseconds();;
    },
    lastSixMonthsDateByMilliseconds() {
        const currentMonthDays = new Date(Date.now()).getDay();
        const currentMonth = new Date(Date.now()).getMonth();
        let lastSixMonthsMilliseconds = currentMonthDays * 24 * 60 * 60 * 1000;

        for (let i = 0; i <= 4; i++) {
            const monthIndex = (currentMonth - 1 - i < 0) ? 12 + (currentMonth - 1 - i) : currentMonth - 1 - i;

            lastSixMonthsMilliseconds += monthsToDates[monthIndex].days * 24 * 60 * 60 * 1000;
        }

        lastSixMonthsMilliseconds += 
            (monthsToDates[12 - (currentMonth - 6)].days - currentMonthDays) * 24 * 60 * 60 * 1000 +
            new Date(Date.now()).getHours() * 60 * 60 * 1000 +
            new Date(Date.now()).getMinutes() * 60 * 1000 +
            new Date(Date.now()).getSeconds() * 1000 +
            new Date(Date.now()).getMilliseconds();

        return Date.now() - lastSixMonthsMilliseconds;
    },
    lastThreeMonthsDateByMilliseconds() {
        const currentMonthDays = new Date(Date.now()).getDay();
        const currentMonth = new Date(Date.now()).getMonth();
        let lastThreeMonthsMilliseconds = currentMonthDays * 24 * 60 * 60 * 1000;

        for (let i = 0; i <= 1; i++) {
            const monthIndex = (currentMonth - 1 - i < 0) ? 12 + (currentMonth - 1 - i) : currentMonth - 1 - i;

            lastThreeMonthsMilliseconds += monthsToDates[monthIndex].days * 24 * 60 * 60 * 1000;
        }

        lastThreeMonthsMilliseconds += 
            (monthsToDates[(currentMonth - 3 < 0) ? 12 + (currentMonth - 3) : (currentMonth - 3)].days - currentMonthDays - 1) * 24 * 60 * 60 * 1000 +
            new Date(Date.now()).getHours() * 60 * 60 * 1000 +
            new Date(Date.now()).getMinutes() * 60 * 1000 +
            new Date(Date.now()).getSeconds() * 1000 +
            new Date(Date.now()).getMilliseconds();

        return Date.now() - lastThreeMonthsMilliseconds;
    },
    lastThirtyDaysDateByMilliseconds() {
        return Date.now() - 2505600000
                          - new Date(Date.now()).getHours() * 60 * 60 * 1000 +
                          - new Date(Date.now()).getMinutes() * 60 * 1000 +
                          - new Date(Date.now()).getSeconds() * 1000 +
                          - new Date(Date.now()).getMilliseconds();
    },
    lastSevenDaysDateByMilliseconds() {
        return Date.now() - 518400000
                          - new Date(Date.now()).getHours() * 60 * 60 * 1000 +
                          - new Date(Date.now()).getMinutes() * 60 * 1000 +
                          - new Date(Date.now()).getSeconds() * 1000 +
                          - new Date(Date.now()).getMilliseconds();
    }
}