import Header from "./header/Header";

const MainPage = () => {
    return (
        <div className="main-page">
            <Header />
        </div>
    );
};

export default MainPage;