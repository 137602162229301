import { Flex, Typography } from "antd";
import SelectComponent from "../../generalComponents/inputFields/selectComponent/SelectComponent";
import LineChart from "../../generalComponents/statisticCharts/LineChart";
import Loader from "../../generalComponents/loaders/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDataApi } from "../../apis/getDataApi";
import { postDataApi } from "../../apis/postDataApi";
import { getDates } from "../../utils/helpers/getDates";
import { makeTransactionsForStatistics } from "../../utils/helpers/makeTransactionsForStatistics";
import { dateIntervalDetector } from "../../utils/helpers/dateIntervalDetector";
import { trxTypesDetector } from "../../utils/helpers/trxtypesDetector";
import { useRefreshToken } from "../../utils/customHooks/useRefreshToken";
import { useLogOutUser } from "../../utils/customHooks/useLogOutUser";
import { urls } from "../../constants/urls/urls";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const StatisticsPage = () => {
    const refreshToken = useRefreshToken();
    const logOutUser = useLogOutUser();
    const { t, i18n } = useTranslation();
    const { Text } = Typography;

    const [ isTokenChanged, setIsTokenChanged ] = useState(false);
    const [ body, setBody ] = useState({
        startDate: dayjs(new Date(getDates.lastSevenDaysDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(new Date(Date.now())).format("YYYY-MM-DD HH:mm:ss"),
    });
    const [ transactions, setTransactions ] = useState({});
    const [ transactionTypes, setTransactionTypes ] = useState([]);
    const [ selectedDateInterval, setSelectedDateInterval ] = useState("week");
    const [ selectedTransactionType, setSelectedTransactionType ] = useState("Վաճառք");
    const [ showLoading, setShowLoading ] = useState(false);

    useEffect(() => {
        switch (selectedDateInterval) {
            case "week":
                setBody({
                    ...body,
                    startDate: dayjs(new Date(getDates.lastSevenDaysDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss")
                });
                break;
            case "month":
                setBody({
                    ...body,
                    startDate: dayjs(new Date(getDates.lastThirtyDaysDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss")
                });
                break;
            case "threeMonths":
                setBody({
                    ...body,
                    startDate: dayjs(new Date(getDates.lastThreeMonthsDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss")
                });
                break;
            case "sixMonths":
                setBody({
                    ...body,
                    startDate: dayjs(new Date(getDates.lastSixMonthsDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss")
                });
                break;
            case "year":
                setBody({
                    ...body,
                    startDate: dayjs(new Date(getDates.oneYearAgoDateByMilliseconds())).format("YYYY-MM-DD HH:mm:ss")
                });
                break;
            default: break;
        }
    }, [selectedDateInterval]);

    useEffect(() => {
        const getTransactions = async () => {
            setShowLoading(true);
            const response = await postDataApi(urls.TRANSACTIONS_STATISTIC_URL, body);
            setShowLoading(false);

            if (response.status === 200) {
                setTransactions(makeTransactionsForStatistics(response.data, selectedDateInterval, body));
            } else if (response.status === 401) {
                setShowLoading(true);
                await refreshToken();
                setShowLoading(false);

                setIsTokenChanged(!isTokenChanged);

                setShowLoading(true);
                const response = await postDataApi(urls.TRANSACTIONS_STATISTIC_URL, body);
                setShowLoading(false);

                if (response.status === 200) {
                    setTransactions(makeTransactionsForStatistics(response.data, selectedDateInterval, body));
                } else {
                    logOutUser();
                }
            }
        };
        getTransactions();
    }, [body]);

    useEffect(() => {
        const getTransactionTypes = async () => {
            setShowLoading(true);
            const response = await getDataApi(urls.TRANSACTION_TYPES_URL);
            setShowLoading(false);

            if (response.status === 200) {
                setTransactionTypes(response.data);
            }
        };
        getTransactionTypes();
    }, [isTokenChanged]);

    const transactionsTypesList = [];
    transactionTypes.map((transactionType) => {
        transactionsTypesList.push(transactionType[`name_${i18n.language}`]);

        return transactionsTypesList;
    });

    return (
        <div className="statistics-page">
            <Flex justify="space-between" style={{ marginBottom: "25px" }}>
                <Flex style={{ marginTop: "15px" }}>
                    <SelectComponent 
                        label={t("searchArea.chooseDateInterval")}
                        chooseData={[
                            `${t("dateIntervals.lastWeek")}`,
                            `${t("dateIntervals.lastMonth")}`,
                            `${t("dateIntervals.lastThreeMonths")}`,
                            `${t("dateIntervals.lastSixMonths")}`,
                            `${t("dateIntervals.lastYear")}`,
                        ]}
                        defaultValue={t("dateIntervals.lastWeek")}
                        width={"300px"}
                        onChooseHandler={(e) => {
                            setSelectedDateInterval(dateIntervalDetector[e.target.value]);
                        }} 
                    />
                    <SelectComponent 
                        label={t("searchArea.chooseTrxType")}
                        chooseData={transactionsTypesList}
                        defaultValue={t("trxTypes.sale")}
                        width={"300px"}
                        onChooseHandler={(e) => {
                            const selectedTransactionType = 
                                transactionTypes.filter((transactionType) => transactionType.id === trxTypesDetector[e.target.value]);
                            setSelectedTransactionType(selectedTransactionType[0].name_am);
                        }} 
                    />
                </Flex>
                <Flex style={{ flexDirection: "column", marginTop: "0px", paddingRight: "15px" }}>
                    <Text level={5} style={{ marginTop: "0px", marginBottom: "3px" }}>{t("searchArea.chooseOtherDateInterval")}</Text>
                    <Flex justify="space-between" style={{ width: "430px" }}>
                        <DatePicker 
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            showIcon
                            showTimeSelect
                            timeIntervals={15}
                            minDate={getDates.lastSixMonthsDateByMilliseconds()}
                            maxDate={Date.now()}
                            showYearDropdown
                            selected={
                                Date.parse(body.startDate) < getDates.lastSixMonthsDateByMilliseconds() ? 
                                    new Date(getDates.lastSixMonthsDateByMilliseconds()) : 
                                    new Date(body.startDate)}
                            onChange={(date) => {
                                setSelectedDateInterval("custom");
                                setBody({
                                    ...body,
                                    startDate: dayjs(new Date(date)).format("YYYY-MM-DD HH:mm:ss")
                                });
                            }} 
                        />
                        <DatePicker 
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeFormat="HH:mm"
                            showIcon
                            showTimeSelect
                            timeIntervals={15}
                            minDate={body.startDate}
                            maxDate={Date.now()}
                            showYearDropdown
                            selected={new Date(body.endDate)}
                            onChange={(date) => {
                                setSelectedDateInterval("custom");
                                setBody({
                                    ...body,
                                    endDate: dayjs(new Date(date)).format("YYYY-MM-DD HH:mm:ss")
                                });
                            }} 
                        />
                    </Flex>
                </Flex>
            </Flex>
            <div style={{ width: selectedDateInterval === "week" ? "800px" : null, height: "400px" }}>
                {
                    selectedTransactionType === "Վաճառք" ? <LineChart data={transactions.saleStatistic} diagramColor={"#008000"} /> :
                    selectedTransactionType === "Չեղարկում" ? <LineChart data={transactions.cancelStatistic} diagramColor={"#FF0000"} /> :
                    selectedTransactionType === "Վերադարձ" ? <LineChart data={transactions.refundStatistic} diagramColor={"#0000FF"} /> : null
                }
            </div>
            {showLoading &&
                <Loader />
            }
        </div>
    );
};

export default StatisticsPage;