import { monthsToDates } from "../../constants/dateTimes/monthsToDates";
import { getDates } from "./getDates";
import dayjs from "dayjs";

export const makeTransactionsForStatistics = (transactions, dateInterval = "week", { startDate, endDate }) => {
    const saleTransactions = {};
    const cancelTransactions = {};
    const refundTransactions = {};

    const sevenDaysBefore = getDates.lastSevenDaysDateByMilliseconds();
    const thirtyDaysBefore = getDates.lastThirtyDaysDateByMilliseconds();
    const threeMonthsBefore = getDates.lastThreeMonthsDateByMilliseconds();
    const sixMonthsBefore = getDates.lastSixMonthsDateByMilliseconds();
    const oneYearBefore = getDates.oneYearAgoDateByMilliseconds();

    switch (dateInterval) {
        case "week":
            for (let i = 0; i <= 6; i++) {
                saleTransactions[dayjs(new Date(sevenDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                cancelTransactions[dayjs(new Date(sevenDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                refundTransactions[dayjs(new Date(sevenDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
            }
            break;
        case "month":
            for (let i = 0; i <= 29; i++) {
                saleTransactions[dayjs(new Date(thirtyDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                cancelTransactions[dayjs(new Date(thirtyDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                refundTransactions[dayjs(new Date(thirtyDaysBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
            }
            break;
        case "threeMonths":
            for (let i = 0; i <= Math.floor((Date.now() - threeMonthsBefore) / 86400000); i++) {
                saleTransactions[dayjs(new Date(threeMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                cancelTransactions[dayjs(new Date(threeMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                refundTransactions[dayjs(new Date(threeMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
            }
            break;
        case "sixMonths":
            for (let i = 0; i <= Math.floor((Date.now() - sixMonthsBefore) / 86400000); i++) {
                saleTransactions[dayjs(new Date(sixMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                cancelTransactions[dayjs(new Date(sixMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
                refundTransactions[dayjs(new Date(sixMonthsBefore + i * 86400000)).format("YYYY-MM-DD")] = [];
            }
            break;
        case "year":
            saleTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()] = [];
            cancelTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()] = [];
            refundTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()] = [];

            for (let i = new Date(oneYearBefore).getMonth() + 1; i < monthsToDates.length; i++) {
                saleTransactions[monthsToDates[i].fullName] = [];
                cancelTransactions[monthsToDates[i].fullName] = [];
                refundTransactions[monthsToDates[i].fullName] = [];
            }
            for (let i = 0; i <= new Date(oneYearBefore).getMonth(); i++) {
                saleTransactions[monthsToDates[i].fullName] = [];
                cancelTransactions[monthsToDates[i].fullName] = [];
                refundTransactions[monthsToDates[i].fullName] = [];
            }
            break;
        case "custom":
            const startDateByMilliseconds = Date.parse(startDate);
            const endDateByMilliseconds = Date.parse(endDate);
            const daysCount = Math.ceil((endDateByMilliseconds - startDateByMilliseconds) / 86400000);

            for (let i = 0; i < daysCount; i++) {
                saleTransactions[dayjs((Date.parse(startDate) + i * 86400000)).format("YYYY-MM-DD")] = [];
                cancelTransactions[dayjs(Date.parse(startDate)  + i * 86400000).format("YYYY-MM-DD")] = [];
                refundTransactions[dayjs(Date.parse(startDate)  + i * 86400000).format("YYYY-MM-DD")] = [];    
            }
            break;
        default: break;
    }

    const currentYear = new Date(Date.now()).getFullYear();
    const currentMonth = new Date(Date.now()).getMonth();

    transactions.map((transaction) => {
        if (
            transaction.transactionType === "Վաճառք" ||
            transaction.transactionType === "Sale" ||
            transaction.transactionType === "Оплата"
        ) {
            if (dateInterval === "year") {
                if (
                    new Date(transaction.createdAt).getMonth() === currentMonth &&
                    new Date(transaction.createdAt).getFullYear() !== currentYear
                ) {
                    saleTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()].push(transaction);
                } else {
                    saleTransactions[monthsToDates[new Date(transaction.createdAt).getMonth()].fullName].push(transaction);
                }
            } else {
                saleTransactions[transaction.createdAt.slice(0, 10)].push(transaction);
            }
        } else if (
            transaction.transactionType === "Չեղարկում" ||
            transaction.transactionType === "Cancel" ||
            transaction.transactionType === "Отмена"
        ) {
            if (dateInterval === "year") {
                if (
                    new Date(transaction.createdAt).getMonth() === currentMonth &&
                    new Date(transaction.createdAt).getFullYear() !== currentYear
                ) {
                    cancelTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()].push(transaction);
                } else {
                    cancelTransactions[monthsToDates[new Date(transaction.createdAt).getMonth()].fullName].push(transaction);
                }
            } else {
                cancelTransactions[transaction.createdAt.slice(0, 10)].push(transaction);
            }
        } else if (
            transaction.transactionType === "Վերադարձ" ||
            transaction.transactionType === "Refund" ||
            transaction.transactionType === "Возврат") {
            if (dateInterval === "year") {
                if (
                    new Date(transaction.createdAt).getMonth() === currentMonth &&
                    new Date(transaction.createdAt).getFullYear() !== currentYear
                ) {
                    refundTransactions[monthsToDates[new Date(oneYearBefore).getMonth()].fullName + "-" + new Date(oneYearBefore).getFullYear()].push(transaction);
                } else {
                    refundTransactions[monthsToDates[new Date(transaction.createdAt).getMonth()].fullName].push(transaction);
                }
            } else {
                refundTransactions[transaction.createdAt.slice(0, 10)].push(transaction);
            }
        }

        return transaction;
    });

    const saleStatistic = [];
    const cancelStatistic = [];
    const refundStatistic = [];

    for (const date in saleTransactions) {
        let sumOfAmounts = 0;

        if (!date.length) {
            saleStatistic.push({
                day: date,
                amount: 0,
                type: "Bor"
            });
        } else {
            saleTransactions[date].map((transaction) => {
                sumOfAmounts += transaction.amount;
                
                return sumOfAmounts;
            });
    
            saleStatistic.push({
                day: date,
                amount: sumOfAmounts / 100,
                type: "Lon"
            });
        }       
    }
    for (const date in cancelTransactions) {
        let sumOfAmounts = 0;

        if (!date.length) {
            cancelStatistic.push({
                day: date,
                amount: 0,
                type: "Bor"
            });
        } else {
            cancelTransactions[date].map((transaction) => {
                sumOfAmounts += -transaction.amount;

                return sumOfAmounts;
            });
    
            cancelStatistic.push({
                day: date,
                amount: sumOfAmounts / 100,
                type: "Lon"
            });
        }
    }
    for (const date in refundTransactions) {
        let sumOfAmounts = 0;

        if (!date.length) {
            refundStatistic.push({
                day: date,
                amount: 0,
                type: "Bor"
            });
        } else {
            refundTransactions[date].map((transaction) => {
                sumOfAmounts += -transaction.amount;

                return sumOfAmounts;
            });
    
            refundStatistic.push({
                day: date,
                amount: sumOfAmounts / 100,
                type: "Lon"
            });
        }
    }

    return {
        saleStatistic,
        cancelStatistic,
        refundStatistic
    };
};