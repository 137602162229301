export const isChangedTerminalData = (terminalDataForChange, currentTerminalData) => {
    let isChangedAnyData = false;

    for (let field in terminalDataForChange) {
        if (field === "mcc_id") {
            if (terminalDataForChange[field] !== currentTerminalData["mcc"]) {
                isChangedAnyData = true;
            }
        }
        else if (field === "city_id") {
            if (terminalDataForChange[field] !== currentTerminalData["city"]) {
                isChangedAnyData = true;
            }
        }
        else {
            if (terminalDataForChange[field] !== currentTerminalData[field] && field !== "merchantPhoneNumber") {
                isChangedAnyData = true;
            }
        }
    }

    return isChangedAnyData;
};