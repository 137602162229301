import "./TerminalsPage.css";
import ChangeTerminalData from "./changeTerminalData/ChangeTerminalData";
import DeleteTerminalData from "./deleteTerminalData/DeleteTerminalData";
import TermPageSearchArea from "./termPageSearchArea/TermPageSearchArea";
import Table from "../../generalComponents/table/Table";
import ModalComponent from "../../generalComponents/modalComponent/ModalComponent";
import PaginationComponent from "../../generalComponents/pagination/Pagination";
import Loader from "../../generalComponents/loaders/Loader";
import { getDataApi } from "../../apis/getDataApi";
import { useRefreshToken } from "../../utils/customHooks/useRefreshToken";
import { useLogOutUser } from "../../utils/customHooks/useLogOutUser";
import { makeObjFieldsToString } from "../../utils/helpers/makeObjFieldsToString";
import { changeTerminalsFieldsForView } from "../../utils/helpers/changeTerminalsFieldsForView";
import { urls } from "../../constants/urls/urls";
import { savePosModels } from "../../redux/slices/posModels/posModelsSlice";
import { savePaymentSystems } from "../../redux/slices/paymentSystems/paymentSystemsSlice";
import { saveCities } from "../../redux/slices/cities/citiesSlice";
import { saveMccs } from "../../redux/slices/mccs/mccsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TerminalsPage = () => {
    const [ isTokenChanged, setIsTokenChanged ] = useState(false);
    const [ terminals, setTerminals ] = useState([]);
    const [ terminalsPageCount, setTerminalsPageCount ] = useState(1);
    const [ isTermDataChanged, setIsTermDataChanged ] = useState(false);
    const [ isTermDataDeleted, setIsTermDataDeleted ] = useState(false);
    const [ isTermDataSearched, setIsTermDataSearched ] = useState(false);
    const [ terminalsPage, setTerminalsPage ] = useState(1);
    const [ isSearchedTerminalsData, setIsSearchedTerminalsData ] = useState(false);    // For terminals search
    const [ terminalsPageForSearch, setTerminalsPageForSearch ] = useState(1);          // For terminals search
    const [ searchedTerminalsPageCount, setSearchedTerminalsPageCount ] = useState(1);  // For terminals search
    const [ selectedTerminal, setSelectedTerminal ] = useState({});
    const [ openCloseDeleteModal, setOpenCloseDeleteModal ] = useState(false);
    const [ openCloseEditModal, setOpenCloseEditModal ] = useState(false);
    const [ showLoading, setShowLoading ] = useState(false);
    const { isMenuOpen } = useSelector((state) => state.menu);
    const refreshToken = useRefreshToken();
    const logOutUser = useLogOutUser();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const windowHeight = window.screen.height;
    const pageSize = windowHeight < 950 ? 5 : 10;

    let paginationLeftMarginClassname = "";
    if (isMenuOpen) paginationLeftMarginClassname = "-open-menu";
    else paginationLeftMarginClassname = "-close-menu";

    useEffect(() => {
        const getCitiesMccPosModelsPaymentSystems = async () => {
            try {
                setShowLoading(true);
                const responseCities = await getDataApi(urls.CITIES_URL);
                const responseMcc = await getDataApi(urls.MCC_URL);
                const responsePosModels = await getDataApi(urls.POS_MODELS_URL);
                const responsePaymentSystems = await getDataApi(urls.PAY_SYS_URL);
                setShowLoading(false);

                if (responseCities.status === 200 &&
                    responseMcc.status === 200 &&
                    responsePosModels.status === 200 &&
                    responsePaymentSystems.status === 200
                ) {
                    dispatch(savePosModels(responsePosModels.data));
                    dispatch(savePaymentSystems(responsePaymentSystems.data));
                    dispatch(saveCities(responseCities.data));
                    dispatch(saveMccs(responseMcc.data));                    
                }
            } catch(err) {
                console.log("Error: ", err.message);
            }
        }
        getCitiesMccPosModelsPaymentSystems();
    }, [isTokenChanged]);

    useEffect(() => {
        try {
            const getTerminalsData = async () => {
                setShowLoading(true);
                const response = await getDataApi(
                    urls.TERMINALS_URL + `?page=${terminalsPage}&size=${pageSize}`
                );
                setShowLoading(false);

                if (response.status === 200) {
                    setTerminals(changeTerminalsFieldsForView(response.data.items, terminalsPage, pageSize));
                    setTerminalsPageCount(response.data.pages);
                    setIsSearchedTerminalsData(false);
                } else if (response.status === 401) {
                    setShowLoading(true);
                    await refreshToken();
                    setShowLoading(false);

                    setIsTokenChanged(!isTokenChanged);

                    setShowLoading(true);
                    const response = await getDataApi(
                        urls.TERMINALS_URL + `?page=${terminalsPage}&size=${pageSize}`
                    );
                    setShowLoading(false);

                    if (response.status === 200) {
                        setTerminals(changeTerminalsFieldsForView(response.data.items, terminalsPage, pageSize));
                        setTerminalsPageCount(response.data.pages);
                        setIsSearchedTerminalsData(false);
                    } else {
                        logOutUser();
                    }
                } else {
                    throw new Error("Connection error!");
                }
            }
            getTerminalsData();
        } catch(err) {
            console.log("Error: ", err.message);
        }
    }, [isTermDataChanged, isTermDataDeleted, terminalsPage, isTermDataSearched]);

    return (
        <div className="terminals-page-area">
            <TermPageSearchArea pageSize={pageSize}
                                terminalsPageForSearch={terminalsPageForSearch}
                                setIsSearchedTerminalsData={setIsSearchedTerminalsData}
                                setSearchedTerminalsPageCount={setSearchedTerminalsPageCount}
                                setTerminals={setTerminals}
                                isSearched={isTermDataSearched}
                                setIsSearched={setIsTermDataSearched}
                                isTermDataChanged={isTermDataChanged}
                                setIsTermDataChanged={setIsTermDataChanged} 
            />
            <Table whichTable={"terminals"}
                   datas={makeObjFieldsToString(terminals)}
                   size="small"
                   windowHeight={windowHeight}
                   minWidth={"1500px"}
                   scrollBoth={true}
                   onClickEditButton={(terminal) => {
                       setSelectedTerminal(terminal);
                       setOpenCloseEditModal(true);
                   }}
                   onClickDeleteButton={(terminal) => {
                       setSelectedTerminal(terminal);
                       setOpenCloseDeleteModal(true);
                   }} />
            <div className={`terminals-page-pagination`}>
                <PaginationComponent pageCount={!isSearchedTerminalsData ? terminalsPageCount : searchedTerminalsPageCount}
                                     setPage={!isSearchedTerminalsData ? setTerminalsPage : setTerminalsPageForSearch}
                                     leftMargin={paginationLeftMarginClassname} />
            </div>
            {openCloseEditModal &&
                <ModalComponent 
                    onCloseHandler={() => setOpenCloseEditModal(false)} 
                    isOpen={openCloseEditModal} 
                    title={t("changeTerminalData.changeTerminalData")}
                    body={<ChangeTerminalData terminal={selectedTerminal}
                    setIsTermDataChanged={setIsTermDataChanged}
                    isTermDataChanged={isTermDataChanged}
                    onCloseHandler={() => setOpenCloseEditModal(false)} />}
                />
            }
            {openCloseDeleteModal &&
                <ModalComponent 
                    onCloseHandler={() => setOpenCloseDeleteModal(false)} 
                    isOpen={openCloseDeleteModal}
                    title={t("inactivateTerminalData.inactivateTerminalData")}
                    body={<DeleteTerminalData terminal={selectedTerminal}
                    setIsTermDataDeleted={setIsTermDataDeleted}
                    isTermDataDeleted={isTermDataDeleted}
                    onCloseHandler={() => setOpenCloseDeleteModal(false)} />}
                />
            }
            {showLoading &&
                <Loader />
            }
        </div>
    );
};

export default TerminalsPage;