import "./ChangeTerminalData.css";
import Button from "../../../generalComponents/buttons/Button";
import TextInput from "../../../generalComponents/inputFields/textInputComponent/TextInputComponent";
import AutoCompleteSelect from "../../../generalComponents/inputFields/autoCompleteSelect/AutoCompleteSelect";
import ModalComponent from "../../../generalComponents/modalComponent/ModalComponent";
import ErrorModalBody from "../../../generalComponents/modalComponent/errorModalBody/ErrorModalBody";
import SuccessAnimation from "../../../generalComponents/successAnimation/SuccessAnimation";
import Loader from "../../../generalComponents/loaders/Loader";
import { putDataApi } from "../../../apis/putDataApi";
import { useRefreshToken } from "../../../utils/customHooks/useRefreshToken";
import { useLogOutUser } from "../../../utils/customHooks/useLogOutUser";
import { getFieldsArrayFromAllObjectsArray } from "../../../utils/helpers/getFieldsArrayFromAllObjectsArray";
import { isChangedTerminalData } from "../../../utils/helpers/isChangedTerminalData";
import { colors } from "../../../assets/styles/colors";
import { useState } from "react";
import { urls } from "../../../constants/urls/urls";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { checkFieldsValidation } from "../../../utils/fieldsValidations/checkEditTermDataFieldsValidation";
import { resetPrevValidations } from "../../../utils/fieldsValidations/resetPrevValidations";

const ChangeTerminalData = ({
    terminal,
    setIsTermDataChanged, 
    isTermDataChanged,
    onCloseHandler
}) => {
    const mccs = useSelector((state) => state.mccs.mccs.payload);
    const cities = useSelector((state) => state.cities.cities.payload);
    const [ terminalDataForChange, setTerminalDataForChange ] = useState({
        id: terminal.id,
        mcc_id: terminal.mcc,
        merchantNameGlobal: terminal.merchantNameGlobal,
        merchantNameLocal: terminal.merchantNameLocal,
        merchantAddressGlobal: terminal.merchantAddressGlobal,
        merchantAddressLocal: terminal.merchantAddressLocal,
        city_id: terminal.city,
        merchantPhoneNumber: null,
        merchantWebPage: terminal.merchantWebPage,
    });
    
    const [ emptyMccError, setEmptyMccError ] = useState(false);
    const [ emptyMerchantNameError, setEmptyMerchantNameError ] = useState(false);
    const [ invalidMerchantNameError, setInvalidMerchantNameError ] = useState(false);
    const [ longMerchantNameError, setLongMerchantNameError ] = useState(false);
    const [ emptyMerchantNameInAmError, setEmptyMerchantNameInAmError ] = useState(false);
    const [ invalidMerchantNameInAmError, setInvalidMerchantNameInAmError ] = useState(false);
    const [ longMerchantNameInAmError, setLongMerchantNameInAmError ] = useState(false);
    const [ emptyMerchantAddressError, setEmptyMerchantAddressError ] = useState(false);
    const [ invalidMerchantAddressError, setInvalidMerchantAddressError ] = useState(false);
    const [ longMerchantAddressError, setLongMerchantAddressError ] = useState(false);
    const [ emptyMerchantAddressInAmError, setEmptyMerchantAddressInAmError ] = useState(false);
    const [ invalidMerchantAddressInAmError, setInvalidMerchantAddressInAmError ] = useState(false);
    const [ longMerchantAddressInAmError, setLongMerchantAddressInAmError ] = useState(false);
    const [ emptyMerchantCityError, setEmptyMerchantCityError ] = useState(false);
    const [ showLoading, setShowLoading ] = useState(false);
    const [ openCloseSuccessModal, setOpenCloseSuccessModal ] = useState(false);
    const [ openCloseErrorModal, setOpenCloseErrorModal ] = useState(false);

    const refreshToken = useRefreshToken();
    const logOutUser = useLogOutUser();
    const { t } = useTranslation();

    const terminalsErrorFields = [
        setEmptyMccError,
        setEmptyMerchantNameError,
        setInvalidMerchantNameError,
        setLongMerchantNameError,
        setEmptyMerchantNameInAmError,
        setInvalidMerchantNameInAmError,
        setLongMerchantNameInAmError,
        setEmptyMerchantAddressError,
        setInvalidMerchantAddressError,
        setLongMerchantAddressError,
        setEmptyMerchantAddressInAmError,
        setInvalidMerchantAddressInAmError,
        setLongMerchantAddressInAmError,
        setEmptyMerchantCityError,
    ];

    const onClickAddButton = async () => {
        resetPrevValidations(terminalsErrorFields);

        if (isChangedTerminalData(terminalDataForChange, terminal)) {
            if (!checkFieldsValidation(terminalDataForChange, terminalsErrorFields)) {
                const terminalDataForCall = {...terminalDataForChange};
    
                mccs.map((mcc) => {
                    if (terminalDataForCall.mcc_id === mcc.code) {
                        terminalDataForCall.mcc_id = mcc.id;
                    }

                    return terminalDataForCall;
                })
    
                cities.map((city) => {
                    if (terminalDataForCall.city_id === city.name_am) {
                        terminalDataForCall.city_id = city.id;
                    }

                    return terminalDataForCall;
                });
    
                setShowLoading(true);
                const response = await putDataApi(urls.CHANGE_TERMINAL_DATA_URL, terminalDataForCall);
                setShowLoading(false);
    
                if (response.status === 200) {
                    setIsTermDataChanged(!isTermDataChanged);
                    setOpenCloseSuccessModal(true);
                    setTimeout(() => {
                        onCloseHandler();
                    }, 3000);
                } else if (response.status === 401) {
                    setShowLoading(true);
                    await refreshToken();
                    setShowLoading(false);
    
                    setShowLoading(true);
                    const response = await putDataApi(urls.CHANGE_TERMINAL_DATA_URL, terminalDataForCall);
                    setShowLoading(false);
    
                    if (response.status === 200) {
                        setIsTermDataChanged(!isTermDataChanged);
                        setOpenCloseSuccessModal(true);
                        setTimeout(() => {
                            onCloseHandler();
                        }, 3000);
                    } else {
                        logOutUser();
                    }
                } else {
                    throw Error("Connection error!");
                }
            }
        } else {
            onCloseHandler();
        }        
    };

    return (
        <div className="add-term-data-area">
            <div className="add-term-data-content">
                <div className="add-term-data-fields">
                    <TextInput 
                        label={t("terminalsSection.serialNumber")}
                        defaultValue={terminal.serial_number} 
                        disabled={true}
                        width="250px"
                    />
                    <TextInput 
                        label={t("terminalsSection.tid")}
                        defaultValue={terminal.terminalId} 
                        disabled={true}
                        marginTop={"10px"}
                        width="250px"
                    />
                    <TextInput 
                        label={t("terminalsSection.mid")}
                        defaultValue={terminal.merchantId} 
                        disabled={true} 
                        marginTop={"10px"}
                        width="250px"
                    />
                    <TextInput 
                        label={t("terminalsSection.posType")}
                        defaultValue={terminal.posModel} 
                        disabled={true} 
                        marginTop={"10px"}
                        width="250px"
                    />
                    <AutoCompleteSelect 
                        label={t("terminalsSection.mcc")}
                        data={getFieldsArrayFromAllObjectsArray(mccs, "code")}
                        defaultValue={terminal.mcc}
                        width={"250px"}
                        marginTop={"10px"}
                        existsError={emptyMccError}
                        errorText={t("searchArea.emptyFieldError")}
                        onChangeHandler={(evt) => {
                            setTerminalDataForChange({
                                ...terminalDataForChange,
                                mcc_id: evt.target.innerText
                            });
                        }} 
                    />
                </div>
                <div className="add-term-data-fields">
                    <TextInput 
                        label={t("terminalsSection.tax")}
                        width="340px"
                        defaultValue={terminal.merchantTin}
                        disabled={true}
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantName")}
                        defaultValue={terminal.merchantNameGlobal}
                        marginTop={"10px"}
                        width="340px"
                        existsError={
                            emptyMerchantNameError || 
                            longMerchantNameError ||
                            invalidMerchantNameError
                        }
                        errorText={
                            emptyMerchantNameError ? t("searchArea.emptyFieldError") :
                            longMerchantNameError ? t("userSection.longFieldError") :
                            invalidMerchantNameError ? t("userSection.onlyEngLettersSymbolsNumbersError") : null
                        }
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantNameGlobal: (evt.target.value).trim()
                        })} 
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantNameAm")}
                        defaultValue={terminal.merchantNameLocal}
                        marginTop={"10px"}
                        width="340px"
                        existsError={
                            emptyMerchantNameInAmError || 
                            longMerchantNameInAmError ||
                            invalidMerchantNameInAmError
                        }
                        errorText={
                            emptyMerchantNameInAmError ? t("searchArea.emptyFieldError") :
                            longMerchantNameInAmError ? t("userSection.longFieldError") :
                            invalidMerchantNameInAmError ? t("userSection.onlyArmLettersSymbolsNumbersError") : null
                        }
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantNameLocal: (evt.target.value).trim()
                        })} 
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantAddress")}
                        defaultValue={terminal.merchantAddressGlobal}
                        marginTop={"10px"}
                        width="340px"
                        existsError={
                            emptyMerchantAddressError || 
                            longMerchantAddressError ||
                            invalidMerchantAddressError
                        }
                        errorText={
                            emptyMerchantAddressError ? t("searchArea.emptyFieldError") :
                            longMerchantAddressError ? t("userSection.longFieldError") :
                            invalidMerchantAddressError ? t("userSection.onlyEngLettersSymbolsNumbersError") : null
                        }
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantAddressGlobal: evt.target.value
                        })} 
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantAddressAm")}
                        defaultValue={terminal.merchantAddressLocal}
                        marginTop={"10px"}
                        width="340px"
                        existsError={
                            emptyMerchantAddressInAmError || 
                            longMerchantAddressInAmError ||
                            invalidMerchantAddressInAmError
                        }
                        errorText={
                            emptyMerchantAddressInAmError ? t("searchArea.emptyFieldError") :
                            longMerchantAddressInAmError ? t("userSection.longFieldError") :
                            invalidMerchantAddressInAmError ? t("userSection.onlyArmLettersSymbolsNumbersError") : null
                        }
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantAddressLocal: evt.target.value
                        })} 
                    />
                </div>
                <div className="add-term-data-fields">
                    <AutoCompleteSelect 
                        label={t("terminalsSection.merchantCity")}
                        defaultValue={terminal.city}
                        data={getFieldsArrayFromAllObjectsArray(cities, "name_am")}
                        existsError={emptyMerchantCityError}
                        errorText={t("searchArea.emptyFieldError")}
                        width="340px"
                        onChangeHandler={(evt) => {
                            setTerminalDataForChange({
                                ...terminalDataForChange,
                                city_id: evt.target.innerText
                            });
                        }} 
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantPhoneNumber")}
                        defaultValue={terminal.merchantPhoneNumber}
                        marginTop={"10px"}
                        width="340px"
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantPhoneNumber: evt.target.value
                        })} 
                    />
                    <TextInput 
                        label={t("terminalsSection.merchantWebPage")}
                        defaultValue={terminal.merchantWebPage}
                        marginTop={"10px"}
                        width="340px"
                        onChangeHandler={(evt) => setTerminalDataForChange({
                            ...terminalDataForChange,
                            merchantWebPage: evt.target.value
                        })} 
                    />
                </div>
            </div>
            <div className="add-term-data-buttons">
                <Button 
                    label={t("addNewTerminal.addBtn")} 
                    marginRight="10px"
                    backgroundColor={colors.successBgColor}
                    hoverColor={colors.successHoverColor}
                    onClickHandler={() => onClickAddButton()} 
                />
                <Button 
                    label={t("addNewTerminal.cancelBtn")} 
                    backgroundColor={colors.cancelBgColor}
                    hoverColor={colors.cancelHoverColor}
                    onClickHandler={() => onCloseHandler()} 
                />
            </div>
            {showLoading &&
                <Loader />
            }
            {openCloseSuccessModal &&
                <SuccessAnimation />
            }
            {openCloseErrorModal &&
            <ModalComponent 
                onCloseHandler={setOpenCloseErrorModal}
                isOpen={true}
                title="Connection failed"
                body={<ErrorModalBody />}
                bgcolor="red" />
            }
        </div>
    );
};

export default ChangeTerminalData;