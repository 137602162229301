import { Line } from '@ant-design/plots';
import React from 'react';

const LineChart = ({ data = [], diagramColor }) => {
    const config = {
        data,
        xField: 'day',
        yField: 'amount',
        colorField: 'type',
        scale: {
            color: {
                range: [diagramColor, 'red'],  // '#2688FF'
            },
        },
        style: {
            lineWidth: 2,
            lineDash: (items) => {
                const { type } = items[0];
                return type === 'Bor' ? [2, 4] : [0, 0];
            },
        },
        interaction: {
            tooltip: {
                render: (e, { title, items }) => {
                    const list = items.filter((item) => item.value);
                    return (
                        <div key={title}>
                        <h4>{title}</h4>
                        {list.map((item) => {
                            const { value, color } = item; // name,
                            return (
                                <div>
                                    <div style={{ margin: 0, display: 'flex' }}>
                                        <div>
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: 6,
                                                    height: 6,
                                                    borderRadius: '50%',
                                                    backgroundColor: color,
                                                    marginRight: 6,
                                                }}
                                            ></span>
                                            {/* <span>{name}</span> */}
                                        </div>
                                        <b>{value} AMD</b>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    );
                },
            },
        },
        legend: false,
    };
    
    return <Line {...config} />;
};

export default LineChart;